@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
html,
body {
  height: 100%;
}

html {
  font-family: "Roboto";
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
}

:root {
  --font-sans: "Roboto";
}

* {
  box-sizing: border-box;
}
